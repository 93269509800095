@use '@angular/material' as mat;

.mat-form-field {
    &-medium {
        @include mat.form-field-density(-1);

        &.mat-form-field-round {
            .mdc-text-field {
                border-radius: 52px;
            }
        }
    }

    &-middle {
        @include mat.form-field-density(-2);

        &.mat-form-field-round {
            .mdc-text-field {
                border-radius: 48px;
            }
        }
    }

    &-small {
        @include mat.form-field-density(-3);

        &.mat-form-field-round {
            .mdc-text-field {
                border-radius: 44px;
            }
        }
    }

    &-little {
        @include mat.form-field-density(-4);

        &.mat-form-field-round {
            .mdc-text-field {
                border-radius: 40px;
            }
        }
    }

    &-tiny {
        @include mat.form-field-density(-6);

        &.mat-form-field-round {
            .mdc-text-field {
                border-radius: 36px;
            }
        }
    }

    &-bitty {
        --mat-form-field-container-height: 32px;
        --mat-form-field-filled-label-display: none;
        --mat-form-field-container-vertical-padding: 4px;
        --mat-form-field-filled-with-label-container-padding-top: 4px;
        --mat-form-field-filled-with-label-container-padding-bottom: 4px;

        &.mat-form-field-round {
            .mdc-text-field {
                border-radius: 32px;
            }
        }
    }

    &-autosize {
        .mat-mdc-form-field-infix {
            width: auto;
        }

        .mat-mdc-select-arrow-wrapper {
            margin: 0 0 0 5px;
        }
    }

    &-rowfix {
        .mat-mdc-form-field-icon-suffix {
            white-space: nowrap;
        }
    }

    &-round {
        .mdc-text-field {
            border-radius: 56px;

            .mdc-line-ripple::after,
            .mdc-line-ripple::before {
                border-bottom-color: transparent !important;
            }
        }

        mat-select-trigger,
        .mat-mdc-select-value,
        .mat-mdc-select-value-text {
            display: inline-flex;
        }
    }
}

.mat-mdc-select,
.mat-mdc-form-field-infix {
    display: inline-flex !important;
}

.mdc-floating-label {
    width: 100%;
}

.mdc-text-field__input {
    text-overflow: ellipsis;
}
