@use '@angular/material' as mat;

.mat-mdc-icon-button {
    min-width: 48px;

    &.mat-icon-button {
        &-medium {
            @include mat.icon-button-density(-1);

            --mdc-icon-button-state-layer-size: 44px;
            width: var(--mdc-icon-button-state-layer-size);
            height: var(--mdc-icon-button-state-layer-size);
            padding: 10px;
            min-width: 44px;
        }

        &-middle {
            @include mat.icon-button-density(-2);

            --mdc-icon-button-icon-size: 20px;
            --mdc-icon-button-state-layer-size: 40px;
            width: var(--mdc-icon-button-state-layer-size);
            height: var(--mdc-icon-button-state-layer-size);
            padding: 10px;
            min-width: 40px;

            .mat-icon {
                font-size: var(--mdc-icon-button-icon-size);
                width: var(--mdc-icon-button-icon-size);
                height: var(--mdc-icon-button-icon-size);
            }
        }

        &-small {
            @include mat.icon-button-density(-3);

            --mdc-icon-button-icon-size: 16px;
            --mdc-icon-button-state-layer-size: 36px;
            width: var(--mdc-icon-button-state-layer-size);
            height: var(--mdc-icon-button-state-layer-size);
            padding: 10px;
            min-width: 36px;

            .mat-icon {
                font-size: var(--mdc-icon-button-icon-size);
                width: var(--mdc-icon-button-icon-size);
                height: var(--mdc-icon-button-icon-size);
            }
        }

        &-little {
            --mdc-icon-button-icon-size: 16px;
            --mdc-icon-button-state-layer-size: 32px;
            width: var(--mdc-icon-button-state-layer-size);
            height: var(--mdc-icon-button-state-layer-size);
            padding: 8px;
            min-width: 32px;

            .mat-icon {
                font-size: var(--mdc-icon-button-icon-size);
                width: var(--mdc-icon-button-icon-size);
                height: var(--mdc-icon-button-icon-size);
            }
        }

        &-tiny {
            --mdc-icon-button-icon-size: 16px;
            --mdc-icon-button-state-layer-size: 28px;
            width: var(--mdc-icon-button-state-layer-size);
            height: var(--mdc-icon-button-state-layer-size);
            padding: 6px;
            min-width: 28px;

            .mat-icon {
                font-size: var(--mdc-icon-button-icon-size);
                width: var(--mdc-icon-button-icon-size);
                height: var(--mdc-icon-button-icon-size);
            }
        }

        &-bitty {
            --mdc-icon-button-icon-size: 16px;
            --mdc-icon-button-state-layer-size: 24px;
            width: var(--mdc-icon-button-state-layer-size);
            height: var(--mdc-icon-button-state-layer-size);
            padding: 4px;
            min-width: 24px;

            .mat-icon {
                font-size: var(--mdc-icon-button-icon-size);
                width: var(--mdc-icon-button-icon-size);
                height: var(--mdc-icon-button-icon-size);
            }
        }
    }
}
