@import 'theme/palette';

.mat-mdc-menu-item,
.mat-mdc-menu-item:visited,
.mat-mdc-menu-item:link {
    &[color='warn'] {
        color: mat-color($warn, A200);

        .mat-icon-no-color.mat-icon,
        .mat-mdc-menu-submenu-icon.mat-icon {
            color: mat-color($warn, A200);
        }

        &:not([disabled]):hover {
            background-color: rgba(mat-color($warn, A200), 0.08);
        }
    }

    &[color='accent'] {
        color: mat-color($accent, A200);

        .mat-icon-no-color.mat-icon,
        .mat-mdc-menu-submenu-icon.mat-icon {
            color: mat-color($accent, A200);
        }

        &:not([disabled]):hover {
            background-color: rgba(mat-color($accent, A200), 0.08);
        }
    }

    &[disabled] {
        color: rgba(mat-color($primary, A200-contrast), 0.5);

        .mat-icon-no-color.mat-icon,
        .mat-mdc-menu-submenu-icon.mat-icon {
            color: rgba(mat-color($primary, A200-contrast), 0.5);
        }
    }
}
